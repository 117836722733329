import { PageProps } from "gatsby"
import React, { useEffect } from "react"
import Layout, { MyLocationEnum } from '../components/Layout'
import SEO, { MetaOG } from "../components/Seo"

interface Props {
  location: PageProps
}
export default ({ location }:Props) => {
  
  const seo_og: MetaOG = {
    title: "Skráning",
    description: "Fjarhöll Marel",
  }

  return (
    <Layout location={location} myLocation={MyLocationEnum.Lobby}>
      <SEO
        title="Skráning"
        description="Fjarhöll Marel"
        og={seo_og}
      />
    </Layout>
  )
}